const ConversationPages = {
  '1': {
    url: 'welcome-to-the-constant-channel',
    title: 'Welcome to the #Constant channel!',
    desc:
      "Welcome to Constant. Give us a shout if you have any questions. We'll be officially announcing Constant soon, so stay tuned for some updates",
  },
  '2': {
    url: 'could-you-share-with-me-contact-of-your-founder',
    title: 'Could you share with me contact of your founder?',
    desc:
      'Hi guys, could you share with me contact of your founder? I would like to invite him/her/them to our private event networking in HCMC on 27th January. I am Remy from Crypto Community Vietnam, one of your team shared the launch event in Vietnam on 24th January.\r',
  },
  '3': {
    url:
      'will-the-value-of-one-constant-will-always-equal-to-one-usd-like-usdt',
    title:
      'Will the value of one constant will always equal to one usd like usdt?\r\n',
    desc:
      "When someone buys Constant, we mint a new coin and deposit their USD into the Constant Vault, an escrow managed by Prime Trust. When someone sells a coin back to the network, we burn Constant and withdraw the equivalent amount in USD from the escrow. That's how we maintain Constant's stability.",
  },
  '4': {
    url: 'you-mean-constant-price-will-never-hit-500',
    title: 'You mean constant price will never hit $500 ?\r',
    desc:
      'What we offer instead is a steady rate of return up to 6%. think of it as a much improved way to store and grow your money, rather than speculating in coins. have a look at the investment section on our website.',
  },
  '5': {
    url: 'how-much-time-you-will-take-for-kyc',
    title: 'How much time you will take for KYC ?',
    desc:
      'Hi there, we try to have kyc done in one day, but in certain instances it may take slightly longer.',
  },
  '6': {
    url: 'how-many-lendersborrows-are-using-the-platform-at-the-moment',
    title: 'How many lenders/borrows are using the platform at the moment?',
    desc:
      "The platform is still fairly new, the volume isn't a lot right now.  We only have a handful of early adopters right now.  Our official launch will be on May 22 (in about 2 weeks) with the full smart contract deployed.",
  },
  '7': {
    url: 'what-do-i-have-to-do-to-get-the-1000-promo-funds-to-start-investing',
    title:
      'What do I have to do to get the 1000 promo funds to start investing?',
    desc:
      'Have you read the blog on the free trial? Here it is - it explains everything you need to know',
  },
  '8': {
    url:
      'if-we-are-investing-with-usd-and-earn-an-interest-how-would-this-impact-my-tax-calculation-at-the-end-of-the-year',
    title:
      'If we are investing with USD and earn an interest how would this impact my tax calculation at the end of the year?',
    desc:
      'For taxes we always recommend talking with a tax expert on your end to make sure you are covered.',
  },
  '9': {
    url: 'are-you-waiting-for-a-zelle-transfer',
    title: 'Are you waiting for a zelle transfer?',
    desc:
      "When you send a zelle transfer, it will arrive instantly.  our team will then convert that to a stablecoin (usd).  that process will take a few hours.  with the weekend, if it hasn't arrived in your account yet, it will arrive first thing on monday.",
  },
  '10': {
    url: 'how-have-you-guys-managed-to-attract-lenders',
    title: 'How have you guys managed to attract lenders?',
    desc:
      "Yep.  we're seeing a healthy pipeline of lenders coming in.  education is definitely a process, not just for us, but for the entire space.",
  },
  '11': {
    url: 'how-long-does-it-take-to-get-filled',
    title: 'How long does it take to get filled?\r',
    desc:
      'We quote a maximum of 24 hours to manage expectations, but it has been significantly faster recently. can you dm me your email address so I can chase it up for you?',
  },
  '12': {
    url:
      'if-the-price-reaches-100-for-one-coin-will-be-possible-to-buy-from-you-for-1',
    title:
      'If the price reaches $100 for one coin, will be possible to buy from you for $1?',
    desc:
      "Yes that's possible.. but our team will work very hard to ensure the price as stable as possible so people can use it in every day use case\r\n",
  },
  '13': {
    url: 'the-usd-to-tomo-conversion-on-the-website-is-not-working',
    title: 'The USD to tomo conversion on the website is not working?',
    desc:
      'Hi there. It is a front end bug then the number does not show up. We are fixing it now.\r',
  },
  '14': {
    url:
      'once-the-loan-is-matched-you-cant-back-out-even-if-you-dont-touch-the-constant',
    title:
      'Once the loan is matched you can’t back out even if you don’t touch the constant ?',
    desc:
      'That’s frustrating, they say saying 50 percent of I retest is due up front damn.',
  },
  '15': {
    url: 'how-do-you-convert-your-usd-to-constant',
    title: 'How do you convert your USD to constant?\r\n',
    desc:
      'Hi, once a loan is approved, you can withdraw it either as CONST, or USD, or one of our supported stablecoins - right from the site. To repay, we deduct from your balance. You can top up with CONST, USD, or any of those stablecoins.\r',
  },
  '16': {
    url: 'how-would-they-validate-the-state-of-the-10k-in-funds',
    title: 'How would they validate the state of the 10k in funds ?',
    desc:
      'The data is public... if the bank can understand smart contract, they can read it :)  i totally see your point.  i think this industry needs a better/friendly user interface to the blockchain data for mass adoption and part of every day life!\n',
  },
  '17': {
    url: 'what-are-the-risks-involved-in-lending-with-constant',
    title: 'What are the risks involved in lending with constant?',
    desc:
      'Great question our p2p platform is risk free! Any unmatched deposits you make are fdic insured,once matched with a borrower they are covered by collateral required from the borrower prior to the loan\r',
  },
  '18': {
    url: 'how-do-you-go-about-getting-the-12-stable-coin-interest-rate',
    title: 'How do you go about getting the 12% stable coin interest rate?',
    desc:
      "So you just set your interest rate manually to 12% when investing. That's correct! \r\n",
  },
  '19': {
    url: 'good-day-where-can-i-buy-a-new-token',
    title: 'Good day! Where can I buy a new token?\r',
    desc: 'Welcome! You want to buy or update Const?\r',
  },
  '20': {
    url: 'is-there-a-way-to-submit-an-investment-on-firefox',
    title: 'Is there a way to submit an investment on Firefox?',
    desc: 'Can you shoot me over a screenshot so I can see it?',
  },
  '21': {
    url: 'whats-the-timeframe-for-kyc-approval',
    title: "What's the timeframe for KYC approval?\r",
    desc:
      'Roughly 3-5 days overseas. Way faster, Same day or atleast within 24 hours in most cases',
  },
  '22': {
    url: 'is-there-3rd-party-review-smart-contract-of-constant-product',
    title: 'Is there 3rd party review smart contract of constant product?\r\n',
    desc:
      'At the moment, we are still looking for the a 3rd party reviewing our smart contracts. But Constant is already insured by FDIC and managed by PrimeTrust.\r\n',
  },
  '23': {
    url: 'where-do-i-see-loan-request-available-loan-offer',
    title: 'Where do I see loan request available loan offer?\r',
    desc:
      'Pro version will do. But not available yet. For now, you can use the market rate on our site to be surely matched.\r',
  },
  '24': {
    url: 'would-you-prefer-to-pay-loans-back-monthly-or-one-time-at-the-end',
    title: 'Would you prefer to pay loans back monthly or one time at the end?',
    desc:
      'The challenge is that constant is a p2p platform, so if we offer 3 options in the borrow side, we need to offer the same 3 options on the invest side, and that will break the orderbook into "too fine grained" and its harder to get a match\r\n',
  },
  '25': {
    url:
      'when-does-the-pro-version-get-released-to-see-the-different-lendingborrow-rate-demandsupply',
    title:
      'When does the pro version get released to see the different lending/borrow rate demand/supply?\n',
    desc:
      "We'll share more info soon!   Mina  is leading the Pro launch.  she's actually showing Pro to a small group of users already to get early feedback.  we want it to be perfect prior to the launch.  i'm super excited about it.  internally we're already using it to monitor the order book for the past few weeks.",
  },
  '26': {
    url: 'some-one-in-here-buy-const-in-the-official-page',
    title: 'Some one in here buy const in the official page?\r',
    desc:
      ' If you want to buy Const on official site, here you go. Feel free to ping me if you have any question.\r\n',
  },
  '27': {
    url:
      'if-someones-having-enough-money-for-150-in-crypto-why-would-they-borrow-money',
    title:
      "If someone's having enough money for 150% in crypto, why would they borrow money?",
    desc:
      'They do not want to lose their crypto investment but are in need of quick cash for any reason such as an emergency etc.\r\n',
  },
  '28': {
    url: 'is-constant-platform-manage-fund-through-ethereum-blockchain',
    title: 'Is constant platform manage fund through ethereum blockchain?',
    desc:
      'Welcome to the group. For your question, we support a wide range of not only ERC20 tokens on ethereum blockchain but also other tokens on other chains like Bitcoin, Tomo,…\r',
  },
  '29': {
    url:
      'are-we-still-working-on-the-option-of-using-your-own-collateral-to-pay-back-the-loan-on-the-site',
    title:
      'Are we still working on the option of using your own collateral to pay back the loan on the site?',
    desc:
      'Hello, great question! Yes we are working on this option so that you can do it within your dashboard. We will keep you updated once available!\r\n',
  },
  '30': {
    url: 'can-you-use-more-than-one-type-of-collateral-for-the-same-loan',
    title: 'Can you use more than one type of collateral for the same loan?',
    desc:
      'At the moment one type for 1 loan just to keep things simplified, but that is a good idea for the future.\r\n',
  },
  '31': {
    url:
      'so-constant-receives-the-vthor-generated-by-the-vet-being-used-as-collateral',
    title:
      'So constant receives the vthor generated by the vet being used as collateral?',
    desc: 'Yes, we do.\r\n',
  },
  '32': {
    url: 'how-do-you-get-the-promotional-rate-with-fantom',
    title: 'How do you get the promotional rate with Fantom?',
    desc:
      'Promotion applies for both borrow and Pro side. Feel free to set your term then. Can you please give me your email so I can check the time-out status for you?\r\n',
  },
  '33': {
    url: 'is-there-a-way-to-speed-up-the-verification-process',
    title: 'Is there a way to speed up the verification process?\r',
    desc:
      'Hi there. Can you please send me your email so I can check that for you?\r',
  },
  '34': {
    url: 'anyone-on-here-want-to-do-a-10k-loan-for-2-years-at-10',
    title: 'Anyone on here want to do a 10k loan for 2 years at 10%?\r',
    desc: 'How long does the matching process take for 10 percent rate ?\r\n',
  },
  '35': {
    url:
      'would-it-possible-to-see-the-price-that-is-driving-the-collateral-loan-to-value-percentage',
    title:
      'Would it possible to see the price that is driving the collateral loan to value percentage?',
    desc:
      'Let me discuss more with the team about that. We will try to figure out a better way to make the calculation of collateral value more transparent.\r',
  },
  '36': {
    url:
      'maybe-an-ama-would-be-good-to-clarify-some-of-the-detailed-questions-here',
    title:
      'Maybe an AMA would be good to clarify some of the detailed questions here?\r',
    desc:
      "Also, an AMA is a great idea! We'll arrange a day and time and get back to you on that\r",
  },
  '37': {
    url:
      'i-send-my-collateral-to-you-then-i-can-withdraw-my-loan-in-stablecoin',
    title:
      'I send my collateral to you. then i can withdraw my loan in stablecoin?',
    desc: 'Exactly ;) if you want further instruction, ping me anytime\r',
  },
  '38': {
    url: 'the-interest-from-investing-in-dgx-is-in-usd-not-in-kind',
    title: 'The interest from investing in DGX is in USD, not in kind?\r\n',
    desc:
      'All the interest is in USD, which you can then withdraw as USD to a bank account or to a wallet as one of our supported stablecoins (you can even withdraw as DGX, subject to conversion and network fee)\r\n',
  },
  '39': {
    url: 'when-you-recall-excess-does-it-take-you-back-to-150-collateral',
    title:
      'When you recall excess, does it take you back to 150% collateral?\r',
    desc:
      'Yes, it is. When you recall excess, the collateral value will reduce to the minimum number required.\r\n',
  },
  '40': {
    url: 'why-only-usdt-can-be-used-as-collateral',
    title: 'Why only usdt can be used as collateral? \r',
    desc:
      'Actually not many users want to use stablecoin as collateral. USDT is there because we have a few special requests about that. And that’s it.\r',
  },
  '41': {
    url: 'can-we-use-ach-to-send-fund----whats-the-ac-number-for-us',
    title: "Can we use ACH to send fund ?   what's the a/c number for us ?\r\n",
    desc:
      'Just make a deposit order as normal. All our bank information will show up and you can choose the most convenient way for you to send funds.\r\n',
  },
  '42': {
    url: 'can-collateral-be-used-to-payoff-loans-now',
    title: 'Can collateral be used to payoff loans now?\r',
    desc:
      'Yes you can. There is an option for you to choose when you press ‘Repay’.\r',
  },
  '43': {
    url: 'whats-the-justification-to-use-security-as-a-reason',
    title: "What's the justification to use security as a reason ?\r",
    desc:
      'We hold a basket of stablecoins (tusd, usdt, usdc, etc) and provide our users a flexible way to covert among these stablecoins.  the deposits are automatically "forwarded" (not spent) to our address on binance to "hold" it there.',
  },
  '44': {
    url:
      'i-would-need-to-send-the-tether-amount-for-the-loan-plus-interest-correct',
    title:
      'I would need to send the tether amount for the loan plus interest correct?',
    desc:
      "If you'd like to pay back your loan before 75% of the term is up, that's absolutely fine. You simply have to pay interest for the days you are in possession of the loan, and just 50% of the original interest rate for the remaining days of the term.\r\n",
  },
  '45': {
    url: 'is-there-an-faq-on-repaying-the-loan-early-eg-can-i-repay-in-usdt',
    title:
      'Is there an FAQ on repaying the loan early? Eg can I repay in USDT?\r\n',
    desc:
      'It’s on the botton of the page. Sure you can repay in USDT. Just make a deposit then click repay button. ',
  },
  '46': {
    url:
      'how-long-till-the-deposit-is-credited-to-my-account-and-how-long-till-it-is-matched',
    title:
      'How long till the deposit is credited to my account and how long till it is matched?\r\n',
    desc: 'Do you deposit in crypto or fiat? Here is our service time. ',
  },
  '47': {
    url: 'if-i-sell-tomo-to-usdt-and-send-that-as-my-collateral-im-safe-no',
    title: 'If i sell tomo to usdt and send that as my collateral im safe no?',
    desc:
      "Sorry if I'm misunderstanding you, do you mean top up with usdt?\r\n",
  },
  '48': {
    url: 'what-would-happen-if-the-system-wont-be-able-to-find-any-lender',
    title: "What would happen if the system won't be able to find any lender?",
    desc:
      'Hi there. Matching time depends on your term and rate, ranging from a moment to forever. If it takes too long to get matched, feel free to cancel your order and make a new one with a better rate.',
  },
  '49': {
    url:
      'is-it-possible-to-use-my-account-and-routing-number-to-wire-transfer-money',
    title:
      'Is it possible to use my account and routing number to wire transfer money?',
    desc:
      'Sure you can do that. Feel free to ping me if you need further assistance.\r',
  },
  '50': {
    url: 'does-anyone-know-if-deposits-via-are-processed-on-weekends',
    title: 'Does anyone know if deposits via are processed on weekends?',
    desc:
      'Hi there. Deposits in fiat during weekends will be processed on the next monday.\r\n',
  },
  '51': {
    url:
      'is-it-possible-for-lenders-to-be-given-the-address-of-the-collateralized-crypto',
    title:
      'Is it possible for lenders to be given the address of the collateralized crypto?',
    desc:
      'Thanks for your suggestion. Let me discuss with the team about that.\r\n',
  },
  '52': {
    url:
      'so-i-take-it-there-is-risk-to-lender-that-they-will-not-get-back-all-of-principal',
    title:
      'So i take it there is risk to lender that they will not get back all of principal?',
    desc:
      'There is a risk and here is how we protect investors.  Thanks for your suggestion. Let me talk with the team about that.',
  },
  '53': {
    url: 'when-i-sign-up-will-it-do-credit-check-or-anything-of-that-thing',
    title:
      'When I sign up will it do credit check or anything of that thing?\r',
    desc:
      'If you use fiat in the US it will require you to provide contact/identity info though (KYC anti money laundering law)\r\n',
  },
  '54': {
    url: 'how-many-fiat-currencies-are-avilable-in-constant',
    title: 'How many fiat currencies are avilable in constant?\r\n',
    desc:
      "Hi Thiago, you can use any currency and we'll convert it to USD for use on the platform. There's no FDIC insurance, but your deposits are backed by collateral put up by borrowers which protects you from defaults\r\n",
  },
  '55': {
    url: 'there-is-no-added-benefit-from-staking-with-constant',
    title: 'There is no added benefit from staking with Constant?',
    desc:
      'Well, Staking is just our new feature and we are working on it to make sure more benefits are coming.\r',
  },
  '56': {
    url: 'for-ama-realted-proposal-whom-should-i-contact',
    title: 'For AMA Realted Proposal Whom should I Contact.??\r',
    desc: ' Hi there. Please ping  @nguyen5  to make business proposal.\r',
  },
  '57': {
    url: 'is-there-an-option-to-borrow-prv-again-usd',
    title: 'Is there an option to borrow prv again usd?',
    desc: 'Hi there. You can’t or can see your open loans?\r',
  },
  '58': {
    url: 'is-it-converted-into-eth-and-staked-in-compound',
    title: 'Is it converted into Eth and staked in compound?',
    desc:
      "Hi all, we get asked a lot about risk on the platform, so here's an in-depth look at our operations and technology – a kind of behind-the-scenes tour if you like – that explains how we protect you ",
  },
  '59': {
    url: 'would-you-mind-sending-me-the-website',
    title: 'Would you mind sending me the website?\r',
    desc:
      "I can't give links but I can only forward messages from official team members because I am not a team member and I am just a user\r",
  },
  '60': {
    url: 'what-happened-to-my-excess-collateral-i-just-sent-off-site',
    title: 'What happened to my excess collateral I just sent off site ?',
    desc: 'It is sent to your wallet.\r\n',
  },
  '61': {
    url: 'please-let-me-clear-121-meaning-figure',
    title: 'Please let me clear 121% meaning figure?',
    desc:
      'Hi there. Auto top-up feature will automatically increase your collateral value to 121% once it falls to 110% (liquidation threshold) if you have enough balance\r\n',
  },
  '62': {
    url: 'i-want-to-know-about-prv-staking-details',
    title: 'I want to know about PRV staking details ?',
    desc: 'You can find basic information about PRV staking here',
  },
  '63': {
    url: 'any-idea-when-it-will-show-up',
    title: 'Any idea when it will show up?',
    desc:
      'Hi mate. For Zelle, it might take a business day to update your account. So your account will be credited on Mon. Can you please give me your email so I can check that for you?\r\n',
  },
  '64': {
    url: 'so-the-6-loans-wont-start-until-then',
    title: 'So the 6% loans wont start until then?',
    desc: 'Yes, please. Just wait until then.\r\n',
  },
  '65': {
    url: 'what-is-the-maximum-deposit-limit-and-withdrawal-limit',
    title: 'What is the maximum deposit limit and withdrawal limit?',
    desc: 'There is no maximum limit',
  },
  '66': {
    url: 'so-there-are-no-fees-for-fiat-withdrawls',
    title: 'So there are no fees for fiat withdrawls?\r\n',
    desc:
      'if you use zelle and follow within the greater or less than paremeters stated\r',
  },
  '67': {
    url: 'what-price-would-liquidation-be',
    title: 'What price would liquidation be?',
    desc:
      'If u wanna borrow 100 usdt, ull have to deposit 150usd worth of ftm\r',
  },
  '68': {
    url: 'if-i-borrow-usdt-i-make-my-payments-in-usdt',
    title: 'If I borrow USDT, I make my payments in USDT?',
    desc:
      'Ill be happy to help out. Sorry for your hardship in the website btw :( \r',
  },
  '69': {
    url:
      'how-much-would-the-price-of-my-assest-have-to-drop-before-it-is-sold-automatically',
    title:
      'How much would the price of my assest have to drop before it is sold automatically?',
    desc: 'depends on your interest rate too\r\n',
  },
  '70': {
    url: 'and-how-often-does-it-update',
    title: 'And how often does it update?\r\n',
    desc: 'Let me check if i can find\r\n',
  },
  '71': {
    url: 'does-anybody-know-how-stars-work-with-the-flex-account',
    title: 'Does anybody know how Stars work with the Flex account?\r',
    desc:
      "Hi mate. To clarify, you can't redeem Star for Flex account. You can  only redeem Star for investments.\r",
  },
  '72': {
    url: 'is-that-why-matched-volume-is-skyrocketing-on-platform',
    title: 'Is that why matched volume is skyrocketing on platform?',
    desc:
      'Your concerns aren’t shared by most investors, and bring huge problems to the platform\r\n',
  },
  '73': {
    url: 'did-you-guys-manage-replenish-your-cashstablecoin-reserves',
    title: 'Did you guys manage replenish your cash/stablecoin reserves?',
    desc:
      'I don’t understand though, if it’s p2p lending liquidity should never be an issue, as you only get matched to an investor who has put their cash up.\n',
  },
  '74': {
    url:
      'while-my-money-is-being-matched-for-a-loan--is-it-making-the-5-interest',
    title:
      'While my money is being matched for a loan,  is it making the 5% interest?',
    desc: "Hey mate. You won't earn the 5% while being matched.\r\n",
  },
  '75': {
    url: 'is-constant-compatible-with-deposits-received-via-smart-contract',
    title:
      'Is Constant compatible with deposits received via smart contract?\r',
    desc: 'My wallet address is a smart contract address\r\n',
  },
  '76': {
    url: 'any-thoughts-on-adding-a-cancel-unstake-option',
    title: 'Any thoughts on adding a cancel unstake option?',
    desc: 'You can unstake anytime mate.\r',
  },
  '77': {
    url:
      'whats-the-number-of-compounding-periods-per-year-for-the-5-interest-rate',
    title:
      "What's the number of compounding periods per year for the 5% interest rate?\r\n",
    desc:
      'There is no period here. You will earn interest right away when your account has balance and you can withdraw any time. Your balance is compounded by every seconds.\r',
  },
  '78': {
    url: 'was-it-related-to-block-confirmations',
    title: 'Was it related to block confirmations?',
    desc:
      "Nope, I was looking under the default 'investment tab' and not the 'loans' tab at the bottom of my accounts page lol\r",
  },
  '79': {
    url: 'hi-do-i-need-kyc-to-stake-prv',
    title: 'Hi do i need kyc to stake prv?\r',
    desc: 'We only require kyc for all fiat related transactions\r',
  },
  '80': {
    url:
      'how-long-after-canceling-investment-order-are-funds-seen-back-in-flex',
    title:
      'How long after canceling investment order are funds seen back in flex?\r',
    desc: 'It will be sent back to your balance right away.\r',
  },
  '81': {
    url: 'whats-maximum-withdrawal-with-paypal',
    title: 'whats maximum withdrawal with paypal?',
    desc:
      'Hi there. We use Const - a stable coin pegged 1:1 to USD as a core to run platform. However, Const can not be traded outside our website.\r',
  },
  '82': {
    url: 'is-there-also-a-max-loan-amount',
    title: 'Is there also a max loan amount?\r',
    desc: 'So let’s say I pay tomorrow after taking out the loan today\r',
  },
  '83': {
    url: 'hey-guys-the-maker-ama-is-today',
    title: 'Hey guys the Maker AMA is today?\r',
    desc:
      'Yes, it is. Please join us for further information. There are gifts for participants as well ',
  },
  '84': {
    url: 'how-is-makerdao-better-compared-to-other-defi-products--te',
    title: 'How is makerdao better compared to other defi products?\r\nTE',
    desc:
      'Why with great features, can be widely used but DAI has only been exchanged on few mid exchanges? Will that make Dai the number of users not equal to other Stablecoins?\r\n',
  },
  '85': {
    url: 'how-can-dai-be-used-on-constant',
    title: 'How can DAI be used on Constant?\r',
    desc:
      'Our collaboration with multi-collateral DAI, the newest version of the famous ETH stablecoin from Maker is one of the first in the DeFi sphere. Read more at\r',
  },
  '86': {
    url: 'are-you-planning-to-do-something-about-that',
    title: 'Are you planning to do something about that ?',
    desc:
      'Our website is currently under maintenance, we are working on fixing the glitches now. It should be back up and running soon\r\n',
  },
  '87': {
    url:
      'do-we-still-hold-custody-of-the-private-keys--assets-when-lending-out-on-constant',
    title:
      'Do we still hold custody of the private keys & assets when lending out on constant?',
    desc: 'Similar to  compound.finance  or dydx or  nuo.network\r\n',
  },
  '88': {
    url: 'how-can-you-track-an-anonymous-transfer',
    title: 'How can you track an anonymous transfer?\r\n',
    desc: 'Yes. You can deposit in pUSDT now. No memo required.\r',
  },
  '89': {
    url: 'how-long-will-it-take-to-deliver-my-rewarded-prvs-to-my-main-balance',
    title:
      'How long will it take to deliver my rewarded PRVs to my main balance?\r',
    desc: '24 hours mate.\r\n',
  },
  '90': {
    url: 'anyone-know-what-the-color-of-bars-on-the-pro-page-represent',
    title: 'Anyone know what the color of bars on the Pro page represent?',
    desc:
      'To clarify, it shows the difference of the rate during the day. If the open rate is higherr than close rate, it will be red. If the close rate is higher than open rate, it will be green.\r',
  },
  '91': {
    url: 'do-you-know-where-i-can-buy-prv-with-fiat',
    title: 'Do you know where I can buy PRV with fiat?\r',
    desc: 'Download the Incognito app.\r\n',
  },
  '92': {
    url: 'is-pusdt-out-of-stock',
    title: 'Is pUSDT out of stock?',
    desc:
      'pusdt is called private usdt which means that you can transact without leaving any traces on the public ledger, and you can convert back to usdt at any time.\r',
  },
  '93': {
    url: 'withdraw-of-usd-with-paypal-is-possible',
    title: 'Withdraw of usd with PayPal is possible ?\r',
    desc:
      'Can you please clarify me a bit about that? You don’t see the buttons there or they are there but don’t work?\r',
  },
  '94': {
    url: 'why-are-the-unstake-and-withdraw-options-empty-on-prv',
    title: 'Why are the unstake and withdraw options empty on PRV?',
    desc:
      'Can you please give me the screenshot so I can check that for you?\r',
  },
  '95': {
    url: 'when-constant-list-ftt',
    title: 'When constant list FTT?\r',
    desc:
      ' On medium there is instruction on how to lend as well mate. Here you go:\r',
  },
  '96': {
    url: 'dear-admin-constant-only-for-us-citizen',
    title: 'Dear admin constant only for us citizen?\r\n',
    desc: 'Constant is worldwide available mate. Everyone is welcome.\r',
  },
  '97': {
    url: 'is-constant-a-platform-available-to-latinamerican-investors',
    title: 'Is constant a platform available to latinamerican investors?',
    desc: "It's available world wide\r\n",
  },
  '98': {
    url: 'am-i-earning-3x-interest-in-the-meantime',
    title: 'Am I earning 3x interest in the meantime?',
    desc: 'Sure mate.\r',
  },
  '99': {
    url: 'when-i-place-an-order-does-my-money-remain-in-flex-until-its-filled',
    title:
      "when I place an order, does my money remain in flex until it's filled?",
    desc: "It does not. I wish it would but it doesn't.\r\n",
  },
  '100': {
    url: 'does-anyone-else-in-here-stake-prv',
    title: 'Does anyone else in here Stake PRV?',
    desc:
      "From what I'm reading, the Incognito home Node seems to return an average of $20/month. Right now I am returning about $38/month Staking 1179 PRV (~$557) in Constant, at 80%APY.\r",
  },
  '101': {
    url: 'any-idea-when-we-are-going-to-get-the-graph-back-on-the-pro-page',
    title: 'Any idea when we are going to get the graph back on the Pro page?',
    desc: 'It is expected to be done within next 1-2 days.\r\n',
  },
  '102': {
    url: 'why-constant-no-longer-wants-to-allow-withdrawls-to-prv',
    title: 'Why Constant no longer wants to allow withdrawls to PRV?',
    desc:
      'We are partnering with Incognito to help promote PRV and their services. The staking percentage has gone from 100% to 50% due to the amount of people that have purchased it',
  },
  '103': {
    url: 'why-matches-not-going-through-despite-same-interest-rate-and-terms',
    title: 'Why matches not going through despite same interest rate and terms',
    desc:
      'The order you see is the sum up of many orders at the same term and rate. It might take a bit longer to match all of them. You can check it at Trade history. It takes a few mins to match an order, one by one, at the same condition.\r\n',
  },
  '104': {
    url: 'what-do-i-do-to-get-the-order-to-matching',
    title: 'What do I do to get the order to matching?\r',
    desc:
      'You have enough collateral deposited?  Sometimes that might be the cause, so check email.\r',
  },
  '105': {
    url: 'can-i-borrow-against-staked-prv',
    title: 'Can I borrow against staked PRV?\r\n',
    desc: 'Unfortunately you unable to at this time. sorry\r\n',
  },
  '106': {
    url: 'when-energy-nrg-or-grin-come-to-myconstan',
    title: 'When Energy NRG or Grin come to myconstan ?',
    desc:
      'Hi mate, we are working with different partners to support more crypto in the future.\r\n',
  },
  '107': {
    url: 'constant-is-not-in-exchanges-yet',
    title: 'Constant is not in exchanges yet ?\r',
    desc:
      'We don’t have plan to list Constant in any exchange in the near future.\r',
  },
  '108': {
    url:
      'is-there-any-restriction-on-depositing-cash-to-constant-and-withdrawing-stable-coin',
    title:
      'Is there any restriction on depositing cash to constant and withdrawing stable coin?',
    desc:
      'You can, but it is not highly recommended to buy a huge amount. \r\n',
  },
  '109': {
    url:
      'does-constant-have-any-investor-relations-page-or-is-it-even-open-to-investors',
    title:
      'Does constant have any investor relations page? Or is it even open to investors?',
    desc: 'What do you want that page to be? Please elaborate more\r',
  },
  '110': {
    url: 'where-can-i-find-the-1099-for-my-account',
    title: 'Where can I find the 1099 for my account?\r\n',
    desc:
      'I’m sorry for the confusion. We don’t issue 1099 form. You need to work with your tax consultant to make it right from your end.\r',
  },
  '111': {
    url: 'what-time-does-constant-office-open-in-vn',
    title: 'What time does constant office open in VN?',
    desc: 'What is that withdrawal mate? Fiat or stablecoin?\r',
  },
  '112': {
    url: 'could-you-explain-to-me-in-what-section-it-is-done',
    title: 'Could you explain to me in what section it is done?',
    desc:
      "I mean let's say you want borrow USDT using crypto as collateral,after you enter the terms and other loan details system gives you deposit address and a  time-out period for you to send your collateral.\r\n",
  },
  '113': {
    url: 'how-long-are-paypal-withdrawals-taking-these-days',
    title: 'How long are paypal withdrawals taking these days?',
    desc:
      'submitted it yesterday and usually gets processed quite quickly so just curious\r',
  },
  '114': {
    url: 'is-the-4-flex-account-now-gone',
    title: 'Is the 4% Flex account now gone?',
    desc:
      'I can understand why with Compound Finance now having rate of 3.4% for USDC\r',
  },
  '115': {
    url: 'are-all-investment-orders-supposed-to-show-up-on-pro',
    title: 'Are all investment orders supposed to show up on Pro?\r',
    desc: 'They’ll be grouped together by similar terms.\r',
  },
  '116': {
    url:
      'is-there-anyway-to-view-which-asset-will-be-used-as-collatoral-for-a-loan',
    title:
      'Is there anyway to view which asset will be used as collatoral for a loan?\r',
    desc:
      'No mate. No matter what is the token, loans are overcollateralised.\r',
  },
  '117': {
    url: 'are-we-still-able-to-withdraw-to-pusdt',
    title: 'Are we still able to withdraw to pUSDT?',
    desc: 'Not at the moment. Please be patient.\r',
  },
  '118': {
    url: 'is-there-an-ongoing-issue-with-the-site',
    title: 'Is there an ongoing issue with the site?',
    desc:
      "Do you have a link to that, i'd be interested in checking that out\r\n",
  },
  '119': {
    url:
      'could-you-tell-this-guy-to-keep-his-political-fud-outside-of-this-group-please',
    title:
      'Could you tell this guy to keep his political fud outside of this group please?',
    desc:
      'People are starting to be out of work “temporarily” due to closings/quarantine\r',
  },
  '120': {
    url: 'why-does-it-take-that-long-if-its-the-native-coin',
    title: 'Why does it take that long if its the native coin?',
    desc:
      "Something's on our platform are done by our system automatically, and there are some things that we sometimes have to do manually. It all depends on the situation\r",
  },
};

module.exports = ConversationPages;
